<template>
	<div class="page_right_content">
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>订单管理</p>
			<i>/</i>
			<p @click="jumpPage">企业订单列表</p>
			<i>/</i>
			<p class="active">企业订单编辑</p>
		</nav>

		<div class="page_edit">
			<div class="page_edit_form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<h4 class="title_h4">基础信息</h4>
					<el-form-item label="" prop="name">
						<p class="form_label_p">企业名称</p>
						<el-autocomplete class="input_long" @select="handleSelect" v-model="ruleForm.name" :disabled="disabledYes2"
							:fetch-suggestions="companySearchAsync" placeholder="请输入企业名称(文本20)"></el-autocomplete>
					</el-form-item>
					<!-- 归属代理商 -->
					<el-form-item v-show="ruleForm.agentName" label="" prop="agentName">
						<p class="form_label_p">归属代理商</p>
						<el-input disabled class="input_long" v-model="ruleForm.agentName" placeholder="请输入企业名称(文本20)">
						</el-input>
					</el-form-item>
					<!-- 订单内容 -->
					<el-form-item label="" prop="">
						<p class="form_label_p">订单内容</p>
						<!-- 1-套餐 2-平台级产品 -->
						<el-radio-group @change="event_to_changeCombo" v-model="ruleForm.orderStatus">
							<el-radio label="1" :disabled="disabledYes2">套餐</el-radio>
							<el-radio label="2" :disabled="disabledYes2">平台级产品</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="" prop="orderType" v-if="ruleForm.orderStatus == '1'" class="form-item_radio_edit">
						<p class="form_label_p"></p>
						<el-radio-group v-model="ruleForm.orderType" size="mini">
							<el-radio class="radio_style" v-for="(radio, i) in orderTypeList" :key="i" border :label="radio.id" :disabled="disabledYes2"
								@change="event_search_meal(radio)">{{radio.combo_name}}
							</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="" prop="orderType" v-else class="form-item_radio_edit form-item_radio_edit_height">
						<p class="form_label_p"></p>
						<div class="platform_level" v-for="(radio, i) in orderTypeList" :key="i">
							<span class="p_1" v-if="radio.cat_name">{{radio.cat_name}}</span>
							<el-radio-group v-model="ruleForm.orderType" size="mini">
								<el-radio class="radio_style" v-for="child in radio.products_list" :key="child.id" border :label="child.id" :disabled="disabledYes2" v-model="ruleForm.orderType"
									@change="event_search_userLevel(child)">{{child.title}}
								</el-radio>
							</el-radio-group>
						</div>
					</el-form-item>
					<el-form-item label="" prop="">
						<p class="form_label_p">关联用户级产品</p>
						<el-checkbox-group v-model="ruleForm.userLevel">
							<el-checkbox v-for="(userLevel, index) in userLevelList" :key="index" :disabled="disabledYes2"
								:label="userLevel.id">
								{{ userLevel.title }}
							</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item label="" prop="">
						<p class="form_label_p">关联设备级产品</p>
						<el-checkbox-group v-model="ruleForm.deviceLevel">
							<el-checkbox v-for="(userLevel, index) in deviceLevelList" :key="index" :disabled="disabledYes2"
								:label="userLevel.id">
								{{ userLevel.title }}
							</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item label="" prop="">
						<p class="form_label_p">时长</p>
						<el-radio-group v-model="ruleForm.productYear">
							<el-radio label="1" :disabled="disabledYes">1年</el-radio>
							<el-radio label="2" :disabled="disabledYes">2年</el-radio>
							<el-radio label="3" :disabled="disabledYes">3年</el-radio>
							<el-radio label="4" :disabled="disabledYes">其它</el-radio>
						</el-radio-group>
						<el-input v-if="ruleForm.productYear == '4'" class="input_mini input_mini_radio" :disabled="disabledYes" v-model="ruleForm.productYear1"
							placeholder="请输入正整数"></el-input>
						<span class="span1">年</span>
					</el-form-item>
					<el-form-item label="" prop="">
						<p class="form_label_p">价格</p>
						<el-input disabled class="input_mini" v-model="computedPrice" placeholder="价格">
						</el-input>
						<span style="margin-left: 16px;">元</span>
					</el-form-item>
					<el-form-item label="" prop="">
						<p class="form_label_p">用户数</p>
						<el-radio-group v-model="ruleForm.productPeople">
							<el-radio label="1" :disabled="disabledYes">1万</el-radio>
							<el-radio label="2" :disabled="disabledYes">2万</el-radio>
							<el-radio label="3" :disabled="disabledYes">3万</el-radio>
							<el-radio label="4" :disabled="disabledYes">其它</el-radio>
						</el-radio-group>
						<el-input v-if="ruleForm.productPeople == '4'" class="input_mini input_mini_radio" :disabled="disabledYes" v-model="ruleForm.productPeople1"
							placeholder="请输入正整数">
						</el-input>
						<span class="span1">个</span>
					</el-form-item>
					<el-form-item label="" prop="">
						<p class="form_label_p">日访问量</p>
						<el-radio-group v-model="ruleForm.productPv">
							<el-radio label="1" :disabled="disabledYes">1万</el-radio>
							<el-radio label="2" :disabled="disabledYes">2万</el-radio>
							<el-radio label="3" :disabled="disabledYes">3万</el-radio>
							<el-radio label="4" :disabled="disabledYes">其它</el-radio>
						</el-radio-group>
						<el-input v-if="ruleForm.productPv == '4'" class="input_mini input_mini_radio" :disabled="disabledYes" v-model="ruleForm.productPv1" placeholder="请输入正整数">
						</el-input>
						<span class="span1">PV</span>
					</el-form-item>
					<el-form-item label="" prop="">
						<p class="form_label_p">设备数量</p>
						<el-radio-group v-model="ruleForm.productDevice">
							<el-radio label="1" :disabled="disabledYes">1万</el-radio>
							<el-radio label="2" :disabled="disabledYes">2万</el-radio>
							<el-radio label="3" :disabled="disabledYes">3万</el-radio>
							<el-radio label="4" :disabled="disabledYes">其它</el-radio>
						</el-radio-group>
						<el-input v-if="ruleForm.productDevice == '4'" class="input_mini input_mini_radio" :disabled="disabledYes" v-model="ruleForm.productDevice1"
							placeholder="请输入正整数">
						</el-input>
						<span class="span1">个</span>
					</el-form-item>
					<div class="form_img_up">
						<p class="title">客户需求</p>
						<nav>
							<li>
								<el-upload :action="$upImg" :on-remove="() => {onRemove(1)}" ref="upload" :limit="1"
									:before-upload="beforeAvatarUpload"
									:on-success="(response) => {uploadSuccess(response, 1)}" :data="imageData"
									list-type="picture" :show-file-list="true" :file-list="SFZfileList">
									<div class="img_up_style">
										<p class="p3">客户需求上传</p>
									</div>
								</el-upload>
							</li>
						</nav>
					</div>
					<div class="form_img_up">
						<p class="title">合同</p>
						<nav>
							<li>
								<el-upload :action="$upImg" :on-remove="() => {onRemove(2)}" ref="upload" :limit="1"
									:before-upload="beforeAvatarUpload" list-type="picture"
									:on-success="(response) => {uploadSuccess(response, 2)}" :data="imageData"
									:show-file-list="true" :file-list="HTfileList">
									<div class="img_up_style">
										<p class="p3">合同</p>
									</div>
								</el-upload>
							</li>
						</nav>
					</div>
					<!-- 合同金额 -->
					<el-form-item label="" prop="agreementPrice">
						<p class="form_label_p">合同金额</p>
						<el-input class="input_mini" :disabled="disabledYes" v-model="ruleForm.agreementPrice" placeholder="请输入合同金额"></el-input>
						<span style="margin-left: 4px;">元</span>
					</el-form-item>
					<div style="height: 16px;"></div>
					<h4 class="title_h4">财务信息</h4>
					<!-- 银行卡信息 -->
					<div class="bank_form_wrap">
						<el-form-item label="" prop="bankName">
							<p class="form_label_p">付款银行信息</p>
							<el-input class="input_long" :disabled="disabledYes" v-model="ruleForm.bankName" placeholder="请输入付款银行名称"></el-input>
						</el-form-item>
						<el-form-item label="" prop="bankCard">
							<p class="form_label_p form_label_p_2">付款银行卡号</p>
							<el-input class="input_long" :disabled="disabledYes" v-model="ruleForm.bankCard" placeholder="请输入付款银行账号"></el-input>
						</el-form-item>
					</div>

					<!-- 首款银行信息 -->
					<div class="bank_form_wrap">
						<el-form-item label="" prop="companyBankName">
							<p class="form_label_p">收款银行信息</p>
							<el-input class="input_long" :disabled="disabledYes" v-model="ruleForm.companyBankName"
								placeholder="请输入收款银行名称"></el-input>
						</el-form-item>
						<el-form-item label="" prop="receipt_bank_no">
							<p class="form_label_p form_label_p_2">收款银行卡号</p>
							<el-input class="input_long" :disabled="disabledYes" v-model="ruleForm.receipt_bank_no" placeholder="请输入收款银行账号">
							</el-input>
						</el-form-item>
					</div>
					<el-form-item label="" prop="">
						<p class="form_label_p">订单状态</p>
						<el-radio-group v-model="ruleForm.order_status">
							<el-radio label="1" :disabled="disabledYes">保存</el-radio>
							<el-radio label="10" :disabled="disabledYes">保存并提交</el-radio>
						</el-radio-group>
					</el-form-item>
					<div class="el-form-item_last"></div>
					<el-form-item class="el-form-item-preservation">
						<div class="page_edit_preservation" v-if="disabledYes">
							<p class="p1" @click="upPage">返回</p>
						</div>
						<div class="page_edit_preservation" v-else>
							<p class="p1" @click="upPage">返回</p>
							<p class="p2" v-if="modifyBtn" @click="createForm('ruleForm')">
								保存
							</p>
							<p class="p2" v-else @click="editForm('ruleForm')">保存</p>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<el-backtop :bottom="130" :right="25"></el-backtop>
	</div>
</template>

<script>
	export default {
		name: "enterpriseOrderEdit",
		data() {
			return {
				ruleForm: {
					name: "",
					agentName: "",
					agentId: "",
					orderType: "", //套餐枚举值
					order_cat: '1', // 订单类型
					saas_order_sn: '', // 请输入资产单号
					orderStatus: "1", //订单内容 radio
					userLevel: [], // 关联用户级产品
					deviceLevel: [], //关联设备级产品
					productYear: "1", //时长
					productYear1: '', // 其它时长
					productPeople: "1", //用户数
					productPeople1: '', //其它用户数
					productPv: "1", //日访问量
					productPv1: "", //其它日访问量
					productDevice: "1", //设备数量
					productDevice1: "", //其它设备数量
					agreementPrice: '', //合同金额
					bankName: "", //银行信息
					bankCard: "", //银行卡号
					companyBankName: "", //收款银行
					receipt_bank_no: '', // 收款银行行号
					order_status: '1', // 保存状态
				},
				setMealObj: {}, //选中的套餐
				SFZImgServe: '', // 身份证正面上传
				SFZfileList: [],
				HTImgServe: '', // 身份证正面上传
				HTfileList: [],
				imageData: {
					ratio: "110x84,375x240",
					rootPath: "gg",
				}, // 上传图片时携带的参数和上传到哪里
				orderTypeList: [], //套餐数组
				userLevelList: [], //用户级产品终端
				deviceLevelList: [], //关联设备级
				rules: {
					name: [{
						required: true,
						message: "名称不能为空",
						trigger: "blur",
					}],
					orderType: [{
						required: true,
						message: "请至少选择一项",
						trigger: "change",
					}],
					agreementPrice: [{
							required: true,
							message: "合同金额不能为空",
							trigger: "blur",
						},
						{
							pattern: /^[0-9]+(.[0-9]{1,2})?$/,
							message: '最多两位小数的数字'
						}
					],
					bankName: [{
						required: true,
						message: "付款银行名称不能为空",
						trigger: "blur",
					}, ],
					bankCard: [{
						required: true,
						message: "付款银行账号不能为空",
						trigger: "blur",
					}, ],
					companyBankName: [{
						required: true,
						message: "收款银行名称不能为空",
						trigger: "blur",
					}, ],
					receipt_bank_no: [{
						required: true,
						message: "收款银行账号不能为空",
						trigger: "blur",
					}, ],
					productYear1: [{
							required: true,
							message: "时长不能为空",
							trigger: "blur",
						}
					],
					productPeople1: [{
							required: true,
							message: "用户数不能为空",
							trigger: "blur",
						}
					],
					productPv1: [{
							required: true,
							message: "日访问量不能为空",
							trigger: "blur",
						}
					],
					productDevice1: [{
							required: true,
							message: "设备数量不能为空",
							trigger: "blur",
						}
					]
				},
				modifyBtn: true, // 默认创建
				disabledYes: false,  // 是否可修改，默认可修改
				disabledYes2: false,  // 续费单时，企业名称和订单内容不可能修改
			};
		},
		components: {},
		created() {
			if (this.$route.query.id) {
				this.modifyBtn = false;
				this.getOrderInfo();
			}
			
			if(this.$route.query.order_cat){
				this.ruleForm.order_cat = this.$route.query.order_cat
				
				// 企业资产单续费
				if(this.$route.query.order_cat == '2'){
					this.disabledYes2 = true;
					this.ruleForm.saas_order_sn = this.$route.query.saas_order_sn;
					
					this.renewOrder();
				}
			}
			
			if(this.$route.query.disabledYes > 1){
				this.disabledYes = true;
				this.disabledYes2 = true;
			}
			
			if (this.ruleForm.orderStatus == "1") {
				this.func_getcomboList();
			} else {
				this.func_getproductslist();
			}
		},
		computed: {
			computedPrice() {
				// 套餐（平台级产品）单价 * 12 * 年限 + 用户级产品单价 * 12 * 年限 + 设备级产品单价 * 12 * 年限 合同价格计算方式
				let price = 0;
				let productPrice = 0;
				if(this.ruleForm.productYear < 4){
					productPrice = this.ruleForm.productYear;
				}
				else{
					productPrice = this.ruleForm.productYear1;
				}
				let setMealPrice = this.setMealObj.price * 12 * productPrice; //套餐价格
				let userLevelPrice = 0; //用户级价格
				let devicePrice = 0; //设备级价格
				let allPrice = 0; //用户 设备 总价格
				//计算用户级价格总和
				this.userLevelList.forEach((item) => {
					this.ruleForm.userLevel.forEach((itemIndex) => {
						if (item.product_id == itemIndex) {
							userLevelPrice = userLevelPrice + Number(item.price)
						}
					})
				})
				//计算平台级价格总和
				this.deviceLevelList.forEach((item) => {
					this.ruleForm.deviceLevel.forEach((itemIndex) => {
						if (item.product_id == itemIndex) {
							devicePrice = devicePrice + Number(item.price)
						}
					})
				})
				allPrice = (userLevelPrice + devicePrice) * 1200 * this.ruleForm.productYear / 100;
				price = Number(allPrice) + Number(setMealPrice);
				return price || 0;
			},
		},
		methods: {
			// 删除文件时的钩子函数
			onRemove(index) {
				if (index == 1) {
					this.SFZImgServe = '';
					this.SFZfileList = [];
				} else if (index == 2) {
					this.HTImgServe = '';
					this.HTfileList = [];
				}
			},
			// 上传前的钩子函数
			beforeAvatarUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 1;
				if (!isLt2M) {
					this.$message({
						message: "上传图片大小不能超过 10MB!",
						type: "warning",
					});
					return;
				}
				return isLt2M;
			},

			// 图片上传成功
			uploadSuccess(response, index) {
				if (index == 1) {
					this.SFZImgServe = response.source;
				} else if (index == 2) {
					this.HTImgServe = response.source;
				}
			},
			
			// 搜索套餐对应设备和用户级
			event_search_meal(radio){
				let id = '';
				if(typeof radio == 'string'){
					id = radio;
				}
				else{
					this.setMealObj = radio; //选中的套餐
					id = radio.id;
				}
				this.$sendHttp({
					method: "POST",
					url: `${process.env.VUE_APP_SAAS}combo/productlistbycomboid`,
					data: {
						combo_id: id,
					},
				}).then( res => {
					if (res.code == "0") {
						this.userLevelList = res.data.user_product_list;
						this.deviceLevelList = res.data.device_product_list;
						
						this.userLevelList.forEach( item => {
							this.$set(item, 'id', item.product_id);
						})
						
						this.deviceLevelList.forEach( item => {
							this.$set(item, 'id', item.product_id);
						});
					
					} else {
						this.$message({
							message: `${res.msg}`,
							type: "warning",
						});
						this.userLevelList = [];
						this.deviceLevelList = [];
					}
				});
				
			},

			//搜索平台对应设备和用户级
			event_search_userLevel(radio) {
				let id = '';
				if(typeof radio == 'string'){
					id = radio;
				}
				else{
					this.setMealObj = radio; //选中的套餐
					id = radio.id;
				}
				
				this.$sendHttp({
					method: "POST",
					url: `${process.env.VUE_APP_SAAS}products/relatedproductlistbypid`,
					data: {
						product_id: id,
					},
				}).then((res) => {
					if (res.code == "0") {
						
						this.userLevelList = [];
						this.deviceLevelList = [];
						
						res.data.forEach( item => {
							if(item.rank == '2'){
								this.userLevelList.push(item);
							}
							else if(item.rank == '3'){
								this.deviceLevelList.push(item);
							}
						})
						console.log(this.userLevelList, this.deviceLevelList)
						
					} else {
						this.$message({
							message: `${res.msg}`,
							type: "warning",
						});
						this.userLevelList = [];
						this.deviceLevelList = [];
					}
				});
			},
			
			
			handleSelect(item) {
				console.log(item);
				this.ruleForm.agentName = item.agent_name;
				this.ruleForm.agentId = item.id;
			},
			
			
			event_to_changeCombo(label) {
				this.ruleForm.orderType = '';
				this.ruleForm.userLevel = [];
				this.ruleForm.deviceLevel = [];
				this.userLevelList = [];
				this.deviceLevelList = [];
				
				if (label == "1") {
					this.func_getcomboList();
				} else {
					this.func_getproductslist();
				}
			},
			
			//获取平台级产品raido
			func_getproductslist() {
				this.$sendHttp({
					method: "POST",
					url: `${process.env.VUE_APP_SAAS}combo/productslist`,
					data: {
						rank: 1,
					},
				}).then((res) => {
					if (res.code == "0") {
						this.orderTypeList = res.data;
						
					} else {
						this.orderTypeList = [];
					}
				});
			},

			//获取套餐radio
			func_getcomboList() {
				this.$sendHttp({
					method: "POST",
					url: `${process.env.VUE_APP_SAAS}combo/combolist`,
					data: {},
				}).then((res) => {
					if (res.code == "0") {
						this.orderTypeList = res.data;
					} else {
						this.orderTypeList = [];
					}
				});
			},

			//根据输入框模糊搜索企业用户列表
			companySearchAsync(queryString, cb) {
				if (!queryString) {
					cb([]);
					return false;
				}
				this.$sendHttp({
					method: "POST",
					url: `${process.env.VUE_APP_SAAS}customuser/listbycompany`,
					data: {
						company: queryString,
					},
				}).then((res) => {
					if (res.code == "0") {
						if(res.data.length > 0){
							//处理数据 给每个对象增加value键值
							let result = res.data.map((x) => {
								x.value = x.company;
								// x.address = "";
								return x;
							});
							cb(result);
						}
						else{
							this.ruleForm.name = '';
						}
					} else {
						this.ruleForm.name = '';
						//若接口异常则返回空对象
						cb([]);
					}
				});
			},
			
			// 获取订单详情做修改
			getOrderInfo(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}order/detail`,
					data: {
						order_sn: this.$route.query.id,
					}
				}).then( res => {
					if(res.code == '0'){
						this.ruleForm.order_cat = res.data.order_cat;
						this.ruleForm.name = res.data.custom_name;
						this.ruleForm.agentId = res.data.custom_id;
						this.ruleForm.agentName = res.data.agent_name;
						this.ruleForm.orderStatus = res.data.order_type;
						
						this.ruleForm.order_status = res.data.order_status;
						this.ruleForm.receipt_bank_no = res.data.receipt_bank_no;
						this.ruleForm.companyBankName = res.data.receipt_bank;
						this.ruleForm.bankCard = res.data.pay_bank_no;
						this.ruleForm.bankName = res.data.pay_bank;
						this.ruleForm.agreementPrice = res.data.contract_amount;
						this.ruleForm.orderType = res.data.order_product_id;
						
						
						if(res.data.useful_life < 4){
							if(!isNaN(res.data.useful_life)){
								this.ruleForm.productYear = res.data.useful_life.toString();
							}
						}
						else{
							this.ruleForm.productYear = '4';
							this.ruleForm.productYear1 = res.data.useful_life
						}
						
						if(res.data.users_limit < 4){
							if(!isNaN(res.data.users_limit)){
								this.ruleForm.productPeople = res.data.users_limit.toString();
							}
						}
						else{
							this.ruleForm.productPeople = '4';
							this.ruleForm.productPeople1 = res.data.users_limit
						}
						
						if(res.data.pv_limit < 4){
							if(!isNaN(res.data.pv_limit)){
								this.ruleForm.productPv = res.data.pv_limit.toString();
							}
						}
						else{
							this.ruleForm.productPv = '4';
							this.ruleForm.productPv1 = res.data.pv_limit
						}
						
						if(res.data.equipment_limit < 4){
							if(!isNaN(res.data.equipment_limit)){
								this.ruleForm.productDevice = res.data.equipment_limit.toString();
							}
						}
						else{
							this.ruleForm.productDevice = '4';
							this.ruleForm.productDevice1 = res.data.equipment_limit
						}
						
						
						if(res.data.saas_order_sn){
							this.ruleForm.saas_order_sn = res.data.saas_order_sn;
						}
						
						if(res.data.user_product_ids){
							this.ruleForm.userLevel = res.data.user_product_ids.split(',');
						}
						
						if(res.data.equipment_product_ids){
							this.ruleForm.deviceLevel = res.data.equipment_product_ids.split(',')
						}
						console.log(this.ruleForm.deviceLevel)
						if(res.data.requirement){
							this.SFZImgServe = res.data.requirement;
							this.SFZfileList.push({
								url: res.data.requirement
							});
						}
						if(res.data.contract){
							this.HTImgServe = res.data.contract;
							this.HTfileList.push({
								url: res.data.contract
							});
						}
						
						if(this.ruleForm.orderStatus == '2'){
							this.func_getproductslist();
							this.event_search_userLevel(this.ruleForm.orderType);
						}
						else{
							this.func_getcomboList();
							this.event_search_meal(this.ruleForm.orderType);
						}
						
						this.setMealPriceFn(this.ruleForm.orderStatus, this.ruleForm.orderType);
						
					}
				})
			},
			
			// 解决获取详情时合同价格显示
			setMealPriceFn(type, id){
				if (type == "1") {
					this.getcomboList(id);
				} else {
					this.getproductslist(id);
				}
			},
			
			//获取套餐radio
			getcomboList(id) {
				this.$sendHttp({
					method: "POST",
					url: `${process.env.VUE_APP_SAAS}combo/combolist`,
					data: {},
				}).then((res) => {
					if (res.code == "0") {
						this.orderTypeList = res.data;
						
						this.orderTypeList.forEach( item => {
							if(item.id == id){
								this.setMealObj = item;
							}
						})
						
					} else {
						this.orderTypeList = [];
					}
				});
			},
			
			//获取平台级产品raido
			getproductslist(id) {
				this.$sendHttp({
					method: "POST",
					url: `${process.env.VUE_APP_SAAS}combo/productslist`,
					data: {
						rank: 1,
					},
				}).then((res) => {
					if (res.code == "0") {
						this.orderTypeList = res.data;
						
						this.orderTypeList.forEach( item => {
							if(item.products_list.length > 0){
								item.products_list.forEach( child => {
									if(child.id == id){
										this.setMealObj = child;
									}
								})
							}
						})
						
					} else {
						this.orderTypeList = [];
					}
				});
			},
			
			// 获取续费单详情
			renewOrder(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}order/renewinfo`,
					data: {
						saas_order_sn: this.ruleForm.saas_order_sn,
					}
				}).then( res => {
					if(res.code == '0'){
						this.ruleForm.order_cat = '2';
						this.ruleForm.name = res.data.custom_name;
						this.ruleForm.agentId = res.data.custom_id;
						this.ruleForm.orderStatus = res.data.order_type;
						this.ruleForm.orderType = res.data.order_product_id;
						this.ruleForm.agentName = res.data.agent_name;
						
						if(res.data.useful_life < 4){
							if(!isNaN(res.data.useful_life)){
								this.ruleForm.productYear = res.data.useful_life.toString();
							}
						}
						else{
							this.ruleForm.productYear = '4';
							this.ruleForm.productYear1 = res.data.useful_life
						}
						
						if(res.data.users_limit < 4){
							if(!isNaN(res.data.users_limit)){
								this.ruleForm.productPeople = res.data.users_limit.toString();
							}
						}
						else{
							this.ruleForm.productPeople = '4';
							this.ruleForm.productPeople1 = res.data.users_limit
						}
						
						if(res.data.pv_limit < 4){
							if(!isNaN(res.data.pv_limit)){
								this.ruleForm.productPv = res.data.pv_limit.toString();
							}
						}
						else{
							this.ruleForm.productPv = '4';
							this.ruleForm.productPv1 = res.data.pv_limit
						}
						
						if(res.data.equipment_limit < 4){
							if(!isNaN(res.data.equipment_limit)){
								this.ruleForm.productDevice = res.data.equipment_limit.toString();
							}
						}
						else{
							this.ruleForm.productDevice = '4';
							this.ruleForm.productDevice1 = res.data.equipment_limit
						}
						
						
						if(res.data.saas_order_sn){
							this.ruleForm.saas_order_sn = res.data.saas_order_sn;
						}
						
						if(res.data.user_product_ids){
							this.ruleForm.userLevel = res.data.user_product_ids.split(',');
						}
						
						if(res.data.equipment_product_ids){
							this.ruleForm.deviceLevel = res.data.equipment_product_ids.split(',')
						}
						
						if(res.data.requirement){
							this.SFZImgServe = res.data.requirement;
							this.SFZfileList.push({
								url: res.data.requirement
							});
						}
						if(res.data.contract){
							this.HTImgServe = res.data.contract;
							this.HTfileList.push({
								url: res.data.contract
							});
						}
						
						if(this.ruleForm.orderStatus == '2'){
							this.func_getproductslist();
							this.event_search_userLevel(this.ruleForm.orderType);
						}
						else{
							this.func_getcomboList();
							this.event_search_meal(this.ruleForm.orderType);
						}
						
						this.setMealPriceFn(this.ruleForm.orderStatus, this.ruleForm.orderType)
					}
				})
			},

			// 创建
			createForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						if(!this.ruleForm.agentId){
							this.ruleForm.name = '';
							this.$alert('按关键字搜索已有的企业', '企业名称', {
								confirmButtonText: '确定',
								callback: () => {}
							});
							return
						}

						let user_product_ids = ''
						if (this.ruleForm.userLevel.length > 0) {
							user_product_ids = this.ruleForm.userLevel.join(',')
						}

						let equipment_product_ids = '';
						if (this.ruleForm.deviceLevel.length > 0) {
							equipment_product_ids = this.ruleForm.deviceLevel.join(',')
						}
						
						let time1 = '';
						if(this.ruleForm.productYear < 4){
							time1 = this.ruleForm.productYear;
						}
						else{
							time1 = this.ruleForm.productYear1;
						}
						
						let time2 = '';
						if(this.ruleForm.productPeople < 4){
							time2 = this.ruleForm.productPeople;
						}
						else{
							time2 = this.ruleForm.productPeople1;
						}
						
						let time3 = '';
						if(this.ruleForm.productPv < 4){
							time3 = this.ruleForm.productPv;
						}
						else{
							time3 = this.ruleForm.productPv1;
						}
						
						let time4 = '';
						if(this.ruleForm.productDevice < 4){
							time4 = this.ruleForm.productDevice;
						}
						else{
							time4 = this.ruleForm.productDevice1;
						}

						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}order/create`,
							data: {
								order_cat: this.ruleForm.order_cat,
								saas_order_sn: this.ruleForm.saas_order_sn,
								custom_id: this.ruleForm.agentId,
								order_type: this.ruleForm.orderStatus,
								order_product_id: this.ruleForm.orderType,
								user_product_ids: user_product_ids,
								equipment_product_ids: equipment_product_ids,
								useful_life: time1,
								users_limit: time2,
								pv_limit: time3,
								equipment_limit: time4,
								contract_amount: this.ruleForm.agreementPrice,
								pay_bank: this.ruleForm.bankName,
								pay_bank_no: this.ruleForm.bankCard,
								receipt_bank: this.ruleForm.companyBankName,
								receipt_bank_no: this.ruleForm.receipt_bank_no,
								order_status: this.ruleForm.order_status,
								requirement: this.SFZImgServe,
								contract: this.HTImgServe,
							}
						}).then(res => {
							if (res.code == '0') {
								this.$message({
									message: '保存成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})

					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},

			// 修改
			editForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						if(!this.ruleForm.agentId){
							this.ruleForm.name = '';
							this.$alert('按关键字搜索已有的企业', '企业名称', {
								confirmButtonText: '确定',
								callback: () => {}
							});
							return
						}
						
						let user_product_ids = ''
						if (this.ruleForm.userLevel.length > 0) {
							user_product_ids = this.ruleForm.userLevel.join(',')
						}
						
						let equipment_product_ids = '';
						if (this.ruleForm.deviceLevel.length > 0) {
							equipment_product_ids = this.ruleForm.deviceLevel.join(',')
						}
						
						let time1 = '';
						if(this.ruleForm.productYear < 4){
							time1 = this.ruleForm.productYear;
						}
						else{
							time1 = this.ruleForm.productYear1;
						}
						
						let time2 = '';
						if(this.ruleForm.productPeople < 4){
							time2 = this.ruleForm.productPeople;
						}
						else{
							time2 = this.ruleForm.productPeople1;
						}
						
						let time3 = '';
						if(this.ruleForm.productPv < 4){
							time3 = this.ruleForm.productPv;
						}
						else{
							time3 = this.ruleForm.productPv1;
						}
						
						let time4 = '';
						if(this.ruleForm.productDevice < 4){
							time4 = this.ruleForm.productDevice;
						}
						else{
							time4 = this.ruleForm.productDevice1;
						}
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}order/edit`,
							data: {
								order_sn: this.$route.query.id,
								order_cat: this.ruleForm.order_cat,
								saas_order_sn: this.ruleForm.saas_order_sn,
								custom_id: this.ruleForm.agentId,
								order_type: this.ruleForm.orderStatus,
								order_product_id: this.ruleForm.orderType,
								user_product_ids: user_product_ids,
								equipment_product_ids: equipment_product_ids,
								useful_life: time1,
								users_limit: time2,
								pv_limit: time3,
								equipment_limit: time4,
								contract_amount: this.ruleForm.agreementPrice,
								pay_bank: this.ruleForm.bankName,
								pay_bank_no: this.ruleForm.bankCard,
								receipt_bank: this.ruleForm.companyBankName,
								receipt_bank_no: this.ruleForm.receipt_bank_no,
								order_status: this.ruleForm.order_status,
								requirement: this.SFZImgServe,
								contract: this.HTImgServe,
							}
						}).then(res => {
							if (res.code == '0') {
								this.$message({
									message: '编辑成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
						
						
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},

			// 返回上一页
			upPage() {
				this.$confirm("系统可能不会保存您所填写的信息。", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
						center: true,
					})
					.then(() => {
						this.$router.go(-1);
					})
					.catch(() => {});
			},

			// 头部跳转页面
			jumpPage() {
				this.$router.go(-1);
			},
		},
	};
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 130px;
	}

	.input_long_left {
		margin-left: 20px;
	}

	.input_mini_radio {
		margin-left: 8px;
	}

	.span1 {
		margin-left: 8px;
		font-size: 14px;
		color: rgba(0, 0, 0, 0.65);
	}

	.user_radio {
		margin-right: 15px;
	}

	// 认证信息图片上传
	.form_img_up {
		padding: 24px;
		background-color: #fff;

		.title {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.85);
			height: 44px;
			line-height: 44px;
		}

		nav {
			display: flex;
			justify-content: flex-start;

			li {
				width: 134px;
				height: 134px;
				border: 1px dashed rgba(0, 0, 0, 0.2);
				background-color: rgba(0, 0, 0, 0.02);
				margin-right: 30px;
				position: relative;

				.img_up_style {
					width: 134px;
					height: 134px;

					.p1 {
						font-size: 14px;
						color: rgba(0, 0, 0, 0.45);
						margin-top: 36px;
					}

					.p2 {
						width: 90px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border-radius: 2px;
						border: 1px solid rgba(0, 0, 0, 0.15);
						margin: 16px auto 0;
					}

					.p3 {
						line-height: 134px;
						font-size: 14px;
						color: rgba(0, 0, 0, 0.65);
					}
				}
			}
		}
	}

	.bank_form_wrap {
		display: flex;
		align-items: flex-end;
		background-color: #fff;

		.form_label_p_2 {
			opacity: 0;
		}
	}
	
	.radio_style{
		margin-bottom: 10px;
	}
	
	.title_h4{
		font-size: 14px;
		color: #000;
		font-weight: bold;
		background-color: #fff;
		padding: 24px 24px 0;
	}
	
	.platform_level{
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		background-color: #fff;
		padding-bottom: 10px;
		.p_1{
			padding: 0 12px;
			border: 1px solid #DCDFE6;
			font-size: 12px;
			position: relative;
			display: inline-block;
			height: 26px;
			line-height: 26px;
			min-width: 60px;
			text-align: center;
		}
	}
	
</style>
